import React, { useState, useEffect } from 'react'
import styles from './Article.module.scss'
import GridItem from './GridItem'
import { Link } from 'gatsby'
import Categories from './Categories'
import Content from '../singlePost/Content'
import Button from '../common/Button'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const Article = ({
  title,
  subtitle,
  image,
  body,
  docs,
  categories,
  tag,
  embedLink,
  heroType,
  next
}) => {
  const getTitle = tag => {
    if (tag === 'docs') {
      return 'Documentation'
    } else if (tag === 'guides') {
      return 'Guides'
    }
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.layout}>
            <div className={styles.leftContainer}>
              <Categories docs={docs} categories={categories} tag={tag} />
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.headerContent}>
                <div className={styles.heading}>
                  <div className={styles.breadcrumb}>
                    <Link to="/help/"> Help</Link> /{' '}
                    <Link to={`/help/${tag}/`}>{getTitle(tag)}</Link> /{' '}
                    <Link
                      to={`/${tag}/${title
                        .toLowerCase()
                        .replace(/\s+/g, '-')}/`}
                    >
                      {title}
                    </Link>
                  </div>
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                </div>
              </div>
              {heroType === 'embed' ? (
                <iframe
                  src={embedLink}
                  width="100%"
                  height="600"
                  frameBorder={0}
                  style={{
                    borderRadius: '10px'
                  }}
                ></iframe>
              ) : (
                <div className={styles.featuredImage}>
                  <img src={image} alt={title} />
                </div>
              )}
              <div className={styles.articleContent}>
                <article itemScope itemType="http://schema.org/BlogPosting">
                  <Content source={body} />
                </article>
                {next && (
                  <div className={styles.nextArticle}>
                    <div className={styles.featuredImage}>
                      <img src={next.featuredImage} alt={next.title} />
                    </div>
                    <div className={styles.nextDetails}>
                      <div>
                        <h5>Up next</h5>
                        <h3>{next.title}</h3>
                        <p>{next.description}</p>
                        <Button
                          variant="minimal"
                          rightIcon={<ArrowRightIcon width={20} />}
                          href={next.link}
                        >
                          Read more
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Article
